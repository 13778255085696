import { gql } from '@apollo/client';

export const GET_STOCK_SAAS = gql`
  query getStockLicenseCode($link: String!) {
    getStockLicenseCode(link: $link) {
      total
      isStockUnlimited
      errorMessage
    }
  }
`;

export const GET_LICENSE_CODES = gql`
  query getLicenseCodes(
    $paymentLinkId: ID!
    $paymentLinkTransactionId: ID
    $membershipCustomerId: ID
  ) {
    getLicenseCodes(
      paymentLinkId: $paymentLinkId
      paymentLinkTransactionId: $paymentLinkTransactionId
      membershipCustomerId: $membershipCustomerId
    ) {
      errorMessage
      licenseCodes {
        licenseCode
        expiredAt
        useCount
        membershipCustomer {
          nextPayment
        }
        paymentLink {
          saasProductInfo {
            activationLimit
          }
        }
      }
    }
  }
`;
