import { gql } from '@apollo/client';

export const GQL_GET_INSTRUCTOR_PAGE_PUBLIC = gql`
  query getInstructorByPaymentLinkId(
    $page: Int!
    $pageSize: Int!
    $paymeLink: String!
    $paymentLinkId: ID
    $link: String
  ) {
    getInstructorByPaymentLinkId(
      page: $page
      pageSize: $pageSize
      paymeLink: $paymeLink
      paymentLinkId: $paymentLinkId
      link: $link
    ) {
      items {
        createdAt
        description
        name
        paymentLinkId
        profile
        profileImageId
        status
        updatedAt
        userId
        profileImage {
          id
          url
        }
      }
      total
    }
  }
`;
