import { gql } from '@apollo/client';

export const GQL_GET_NEWS_FILTERED_LIST_BY_PAYMENTLINKID = gql`
  query getNewsFilteredListByPaymentLinkId($paymentLinkId: ID!, $limit: Int) {
    getNewsFilteredListByPaymentLinkId(paymentLinkId: $paymentLinkId, limit: $limit) {
      createdAt
      description
      id
      title
      status
      updatedAt
    }
  }
`;
