import { gql } from '@apollo/client';

export const GQL_GET_RATE_SHIPMENT_ORDER = gql`
  query getRateShipmentPublic($input: InputRate) {
    getRateShipmentPublic(input: $input) {
      status
      message
      rule
      originArea
      destinationArea
      logistic {
        express {
          name
          logo_url
          rate_id
          show_id
          rate_name
          stop_origin
          stop_destination
          weight
          volumeWeight
          finalWeight
          itemPrice
          item_price
          finalRate
          insuranceRate
          compulsory_insurance
          liability
          discount
          min_day
          max_day
          pickup_agent
          is_hubless
        }
        instant {
          name
          logo_url
          rate_id
          show_id
          rate_name
          stop_origin
          stop_destination
          weight
          volumeWeight
          finalWeight
          itemPrice
          item_price
          finalRate
          insuranceRate
          compulsory_insurance
          liability
          discount
          min_day
          max_day
          pickup_agent
          is_hubless
        }
        regular {
          name
          logo_url
          rate_id
          show_id
          rate_name
          stop_origin
          stop_destination
          weight
          volumeWeight
          finalWeight
          itemPrice
          item_price
          finalRate
          insuranceRate
          compulsory_insurance
          liability
          discount
          min_day
          max_day
          pickup_agent
          is_hubless
        }
        sameDay {
          name
          logo_url
          rate_id
          show_id
          rate_name
          stop_origin
          stop_destination
          weight
          volumeWeight
          finalWeight
          itemPrice
          item_price
          finalRate
          insuranceRate
          compulsory_insurance
          liability
          discount
          min_day
          max_day
          pickup_agent
          is_hubless
        }
        trucking {
          name
          logo_url
          rate_id
          show_id
          rate_name
          stop_origin
          stop_destination
          weight
          volumeWeight
          finalWeight
          itemPrice
          item_price
          finalRate
          insuranceRate
          compulsory_insurance
          liability
          discount
          min_day
          max_day
          pickup_agent
          is_hubless
        }
      }
    }
  }
`;

export const GQL_GET_RATE_SHIPMENT_ORDER_NEW = gql`
  query getRateShipmentKiriminAja($input: InputRateKirim) {
    getRateShipmentKiriminAja(input: $input) {
      status
      message
      rule
      originArea
      destinationArea
      logistic {
        express {
          name
          service_name
          logo_url
          rate_id
          show_id
          rate_name
          itemPrice
          item_price
          finalRate
          insuranceRate
          discount
          min_day
          max_day
          origin_postcode
          destination_postcode
          dropoff
        }
        regular {
          name
          service_name
          logo_url
          rate_id
          show_id
          rate_name
          itemPrice
          item_price
          finalRate
          insuranceRate
          discount
          min_day
          max_day
          origin_postcode
          destination_postcode
          dropoff
        }
        economy {
          name
          service_name
          logo_url
          rate_id
          show_id
          rate_name
          itemPrice
          item_price
          finalRate
          insuranceRate
          discount
          min_day
          max_day
          origin_postcode
          destination_postcode
          dropoff
        }
        trucking {
          name
          service_name
          logo_url
          rate_id
          show_id
          rate_name
          itemPrice
          item_price
          finalRate
          insuranceRate
          discount
          min_day
          max_day
          origin_postcode
          destination_postcode
          dropoff
        }
      }
    }
  }
`;

export const GQL_GET_RATE_SHIPMENT_ORDER_CART = gql`
  query getRateShipmentForCart($input: InputRateCart) {
    getRateShipmentForCart(input: $input) {
      status
      message
      rule
      originArea
      destinationArea
      logistic {
        express {
          name
          logo_url
          rate_id
          show_id
          rate_name
          stop_origin
          stop_destination
          weight
          volumeWeight
          finalWeight
          itemPrice
          item_price
          finalRate
          insuranceRate
          compulsory_insurance
          liability
          discount
          min_day
          max_day
          pickup_agent
          is_hubless
        }
        instant {
          name
          logo_url
          rate_id
          show_id
          rate_name
          stop_origin
          stop_destination
          weight
          volumeWeight
          finalWeight
          itemPrice
          item_price
          finalRate
          insuranceRate
          compulsory_insurance
          liability
          discount
          min_day
          max_day
          pickup_agent
          is_hubless
        }
        regular {
          name
          logo_url
          rate_id
          show_id
          rate_name
          stop_origin
          stop_destination
          weight
          volumeWeight
          finalWeight
          itemPrice
          item_price
          finalRate
          insuranceRate
          compulsory_insurance
          liability
          discount
          min_day
          max_day
          pickup_agent
          is_hubless
        }
        sameDay {
          name
          logo_url
          rate_id
          show_id
          rate_name
          stop_origin
          stop_destination
          weight
          volumeWeight
          finalWeight
          itemPrice
          item_price
          finalRate
          insuranceRate
          compulsory_insurance
          liability
          discount
          min_day
          max_day
          pickup_agent
          is_hubless
        }
        trucking {
          name
          logo_url
          rate_id
          show_id
          rate_name
          stop_origin
          stop_destination
          weight
          volumeWeight
          finalWeight
          itemPrice
          item_price
          finalRate
          insuranceRate
          compulsory_insurance
          liability
          discount
          min_day
          max_day
          pickup_agent
          is_hubless
        }
      }
    }
  }
`;

export const GQL_GET_ORDER = gql`
  query getPaymentLinkByUsername($link: String!) {
    getPaymentLinkByUsername(link: $link, paymentLinkType: physical_product) {
      id
      amount
      description
      name
      status
      startAt
      qty
      userId
      type
      formId
      type
      form {
        fieldObject
        id
        name
      }
      order {
        discountAmount
        id
        variant
        sku
        weight
        length
        width
        height
      }
      payChannelConfig {
        config
        isAkulakuApproved
        isChannelFeeBorneByCustomer
        isAdminFeeBorneByCustomer
        isCreditCardApproved
      }
      feeRules {
        accountId
        createdAt
        fee
        feeRuleId
        type
        updatedAt
        userId
        vendor
      }
      user {
        name
        kyc {
          status
        }
        address {
          district
        }
        payChannelConfig {
          config
          isChannelFeeBorneByCustomer
          isAdminFeeBorneByCustomer
          isAkulakuApproved
          isCreditCardApproved
        }
        account {
          name
          logo {
            url
            width
            height
          }
          isVerified
          planType
          feeRules {
            accountId
            fee
            feeRuleId
            id
          }
        }
        shipmentChannelConfig {
          id
          userId
          config
        }
      }
      multipleImage {
        url
        width
        height
        updatedAt
      }
      errorMessage
      expiredTime
      checkoutSettings {
        buttonName
        buttonNote
        discountButton
        couponOption
        paymentLinkId
        termOption
        termUrl
      }
      conversionSettings {
        type
        trackingId
        conversionSettingEvents {
          name
          page
        }
      }
    }
  }
`;

export const GQL_ORDER_TOTAL = gql`
  query getOrderHistoryTotal($input: OrderHistoryTotal!) {
    getOrderHistoryTotal(input: $input) {
      total
    }
  }
`;

export const GQL_GET_TOTAL_SOLD_ORDER = gql`
  query getTotalSoldOrder($link: String) {
    getTotalSoldOrder(link: $link) {
      total
      detail {
        id
        sku
        qty
      }
      detailEvent {
        id
        qty
        name
      }
    }
  }
`;

export const GQL_TRACK_ORDER_DETAIL_GET = gql`
  query getDetailOrder($orderId: String) {
    getDetailOrder(orderId: $orderId) {
      status
      tracking {
        createdBy
        createdDate
        updatedBy
        updatedDate
        id
        name
        description
      }
      detail {
        id
        externalId
        labelChecksum
        isHubless
        isActive
        isLabelPrinted
        shipmentStatus {
          createdBy
          createdDate
          updatedBy
          updatedDate
          id
          name
          description
        }
      }
    }
  }
`;
