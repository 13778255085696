import { gql } from '@apollo/client';

export const GQL_GET_WEBINARS_BY_USERNAME = gql`
  query getWebinarsByUsername(
    $username: String!
    $page: Int
    $pageSize: Int
    $id: ID
    $link: String
  ) {
    getWebinarsByUsername(
      username: $username
      page: $page
      pageSize: $pageSize
      id: $id
      link: $link
    ) {
      items {
        user {
          account {
            name
            email
            logo {
              url
            }
          }
        }
        eventStartAt
        eventEndAt
        terms
        id
        name
        description
        link
        amount
        status
        coverImage {
          url
        }
      }
      total
    }
  }
`;
