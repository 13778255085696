import { gql } from '@apollo/client';

export const GQL_BALANCE_HISTORY_SUPPORT = gql`
  query getBalanceHistorySupportPublic($link: String!) {
    getBalanceHistorySupportPublic(link: $link) {
      total
      items {
        name
        amount
        message
        userId
      }
    }
  }
`;

export const GQL_GET_SUPPORT_BY_LINK = gql`
  query getSupportByLinkPublic($link: String!) {
    getSupportByLinkPublic(link: $link) {
      id
      unit
      unitIcon
      unitValue
      description
      fontFamily
      buttonStyle
      cardStyle
      coverImage
      isSupporterVisible
      isCopyrightVisible
      socialLinks
      background
      userId
    }
  }
`;
