import { gql } from '@apollo/client';

export const GET_TAGS_PRODUCTS = gql`
  query getListTagsProducts(
    $page: Int
    $sortField: TagSortField
    $sortDirection: SortDirection
    $search: TagSearchInput
    $userId: ID!
  ) {
    getListTagsProducts(
      page: $page
      sortField: $sortField
      sortDirection: $sortDirection
      search: $search
      userId: $userId
    ) {
      responseStatus
      errorMessage
      tags {
        id
        name
      }
      nextPage
    }
  }
`;
