import { gql } from '@apollo/client';

export const GQL_COUPONS_FILTERED_LIST_PUBLIC = gql`
  query getValidateCouponsPublic(
    $paymentLinkId: ID!
    $couponCode: String!
    $userId: ID!
    $finalAmount: Float
    $customerEmail: String
    $membershipTierId: ID
    $paymentLinkType: String
  ) {
    getValidateCouponsPublic(
      paymentLinkId: $paymentLinkId
      couponCode: $couponCode
      userId: $userId
      finalAmount: $finalAmount
      customerEmail: $customerEmail
      membershipTierId: $membershipTierId
      paymentLinkType: $paymentLinkType
    ) {
      errorMessage
      code
      expiredAt
      isActive
      limit
      type
      userId
      discount {
        name
        value
        discountType
        discountProductType
        productDiscount {
          productType
          paymentLinkId
          subscriptionId
        }
      }
    }
  }
`;
