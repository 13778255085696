import { gql } from '@apollo/client';

export const GQL_CREATE_XENDIT_TRANSACTION_PROXY = gql`
  mutation createXenditTransactionProxy($input: CreateXenditProxyInput) {
    createXenditTransactionProxy(input: $input) {
      xenditId
    }
  }
`;

export const GQL_GET_PAYMENT_CHANNEL = gql`
  query getPaymentChannel($id: ID!) {
    getPaymentChannel(id: $id) {
      id
      qr_string
      invoiceURL
      invoiceId
      availableBanks {
        bankCode
        bankAccountNumber
        transferAmount
        accountHolderName
      }
      availableRetailOutlets {
        retailOutletName
        paymentCode
        transferAmount
      }
      actions {
        desktopWebCheckoutUrl
        mobileWebCheckoutUrl
        qrCheckoutString
        mobileDeeplinkCheckoutUrl
      }
      expiryDate
    }
  }
`;
