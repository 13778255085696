import { gql } from '@apollo/client';

export const GQL_UPLOAD_IMAGE = gql`
  mutation uploadCustomerImage($base64: String!, $fileType: String!, $title: String!) {
    uploadCustomerImage(base64: $base64, fileType: $fileType, title: $title) {
      description
      title
      url(width: 480)
      createdAt
      updatedAt
      id
    }
  }
`;

export const GQL_GET_IMAGE_FILTERED_LIST_PUBLIC = gql`
  query getImageFilteredListPublic($search: ImageSearchInput, $paymeLink: String) {
    getImageFilteredListPublic(search: $search, paymeLink: $paymeLink) {
      url
      height
      width
    }
  }
`;
