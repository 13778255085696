import { gql } from '@apollo/client';

export const GET_USER_BY_ID = gql`
  query getUserBundling($id: ID!) {
    getUserBundling(id: $id) {
      id
      payChannelConfig {
        isAdminFeeBorneByCustomer
        isChannelFeeBorneByCustomer
      }
      feeRules {
        id
        fee
        type
      }
      account {
        name
        planType
        logo {
          url
        }
      }
    }
  }
`;

export const GQL_GET_USER_BY_LINK = gql`
  query getUserByPayme($link: String!, $fromLandingPage: Boolean) {
    getUserByPayme(link: $link, fromLandingPage: $fromLandingPage) {
      id
      mobile
      name
      paymeLink
      homePagePath
      publicAnnouncement
      isCatalogVisible
      kyc {
        status
      }
      linkTree {
        background
        buttonStyles
        fontFamily
        isCopyrightVisible
        links
        profile
        socialLinks
        socialLinksPosition
        themeId
      }
      fundraisingSettings {
        isZakatVisible
        isWaqafVisible
        isQurbanVisible
        isNewsVisible
        mainPrograms
        quickDonations
      }
      userAnalytics {
        googleAnalyticsId
        facebookPixelId
        trackToken
        gtmId
      }
      account {
        name
        address
        mobile
        profile
        logo {
          url
        }
        isVerified
      }
      payChannelConfig {
        config
        isAkulakuApproved
        isCreditCardApproved
      }
      ackeeAnalytics {
        analyticDomainId
      }
      support {
        fontFamily
      }
    }
  }
`;

export const GET_USER_BY_LINK = gql`
  query getUserByPayme($link: String!, $fromLandingPage: Boolean) {
    getUserByPayme(link: $link, fromLandingPage: $fromLandingPage) {
      id
      homePagePath
      paymeLink
      userAnalytics {
        googleAnalyticsId
        facebookPixelId
        trackToken
        gtmId
      }
      account {
        name
        address
        mobile
        profile
        logo {
          url
        }
        isVerified
      }
      payChannelConfig {
        config
        isAkulakuApproved
        isCreditCardApproved
      }
    }
  }
`;

export const GQL_GET_USER_BY_REFERRAL_CODE = gql`
  query getUserByReferralCode($referralCode: String!) {
    getUserByReferralCode(referralCode: $referralCode) {
      account {
        name
      }
    }
  }
`;
