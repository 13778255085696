import { gql } from '@apollo/client';

export const GQL_GET_PAYMENTLINK_CONTENT = gql`
  query getUserContentByPaymentLinkId($id: ID!) {
    getUserContentByPaymentLinkId(id: $id) {
      content {
        id
        file {
          url
        }
      }
    }
  }
`;

export const GQL_USER_CONTENT_BY_PAYMENT_LINK_ID = gql`
  query getUserContentByPaymentLinkId($id: ID!) {
    getUserContentByPaymentLinkId(id: $id) {
      id
      user {
        account {
          name
        }
      }
      redirectUrl
      content {
        id
        file {
          url
          filename
          size
        }
      }
    }
  }
`;

export const GQL_USER_CONTENT_BY_COURSE_MODULE_ID = gql`
  query getUserContentByCourseModuleId($id: ID!) {
    getUserContentByCourseModuleId(id: $id) {
      content {
        file {
          url
          filename
          size
        }
      }
    }
  }
`;

export const GQL_GET_MULTIPLE_CUSTOMER_CONTENT = gql`
  query getMultipleCustomerContent($key: String!, $paymentLinkId: ID!) {
    getMultipleCustomerContent(key: $key, paymentLinkId: $paymentLinkId) {
      id
      file {
        createdAt
        filename
        size
        url
      }
    }
  }
`;

export const GQL_DELETE_MULTIPLE_FILE = gql`
  mutation deleteMultipleMultipleFile($ids: [ID]) {
    deleteMultipleMultipleFile(ids: $ids) {
      id
    }
  }
`;
