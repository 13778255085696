import { gql } from '@apollo/client';

export const GET_MEMBERSHIP_PAGE_PUBLIC = gql`
  query getMembershipPageByUsername(
    $username: String!
    $page: Int
    $pageSize: Int
    $id: ID
    $link: String
    $status: String
  ) {
    getMembershipPageByUsername(
      username: $username
      page: $page
      pageSize: $pageSize
      id: $id
      link: $link
      status: $status
    ) {
      errorMessage
      items {
        user {
          paymeLink
          bunnyLibraryId
          account {
            name
            email
            mobile
            profile
            logo {
              url
            }
            isVerified
          }
        }
        membershipInfo {
          showMembers
        }
        id
        type
        amount
        status
        coverImageId
        startAt
        createdAt
        description
        expiredAt
        link
        name
        redirectUrl
        target
        terms
        updatedAt
        userId
        ackeeAnalytics {
          analyticDomainId
          analyticEventId
          eventType
        }
        membershipTier {
          id
          name
          description
          name
          status
          limit
          membershipTierPeriod {
            id
            status
            monthPeriod
            amount
            isLifetime
          }
          membershipTierProducts {
            id
            membershipTierId
            paymentLinkId
            status
            paymentLink {
              id
              status
              description
              name
              type
              coverImage {
                url
              }
            }
          }
        }
        coverImage {
          url
          width
          height
          id
        }
        multipleImage {
          url
          width
          height
          updatedAt
        }
        courseSections {
          description
          id
          name
          position
          link
          status
          isPublicPreview
          isBlurCoverImage
          createdAt
          membershipTierContents {
            membershipTierId
            status
          }
          coverImage {
            id
            url
          }
          coverImageBlur {
            id
            url
          }
          courseModules {
            id
            name
            type
            position
            link
            description
            isPreview
            isContentNotProtected
            isVisible
            coachingUrl
            embedUrl
            quiz
            status
            content {
              file {
                id
                filename
                size
                mimetype
                bunnyVideoId
              }
              id
            }
          }
        }
      }
      conversionSettings {
        trackingId
        type
        conversionSettingEvents {
          name
          page
        }
      }
    }
  }
`;

export const GET_PL_MEMBERSHIP_BY_USERNAME = gql`
  query getPaymentLinkByUsername($link: String!) {
    getPaymentLinkByUsername(link: $link) {
      user {
        paymeLink
        bunnyLibraryId
        account {
          name
          email
          mobile
          profile
          logo {
            url
          }
          isVerified
        }
      }
      membershipInfo {
        showMembers
      }
      id
      type
      amount
      status
      coverImageId
      startAt
      createdAt
      description
      expiredAt
      link
      name
      redirectUrl
      target
      terms
      updatedAt
      userId
      membershipTier {
        id
        name
        description
        name
        status
        limit
        membershipTierPeriod {
          status
          monthPeriod
          amount
        }
        membershipTierProducts {
          id
          membershipTierId
          paymentLinkId
          status
          paymentLink {
            id
            status
            description
            name
            type
            coverImage {
              url
            }
          }
        }
      }
      coverImage {
        url
        width
        height
        id
      }
      conversionSettings {
        trackingId
        type
        conversionSettingEvents {
          name
          page
        }
      }
      courseSections {
        description
        id
        name
        position
        link
        status
        isPublicPreview
        isBlurCoverImage
        createdAt
        membershipTierContents {
          membershipTierId
          status
        }
        coverImage {
          id
          url
        }
        coverImageBlur {
          id
          url
        }
        courseModules {
          id
          name
          type
          position
          link
          description
          isPreview
          isContentNotProtected
          isVisible
          coachingUrl
          embedUrl
          quiz
          status
          content {
            file {
              id
              filename
              size
              mimetype
              bunnyVideoId
            }
            id
          }
        }
      }
    }
  }
`;

export const GQL_GET_PAYMENT_LINK_MEMBERSHIP = gql`
  query getPaymentLinkByUsername($link: String!) {
    getPaymentLinkByUsername(link: $link) {
      user {
        paymeLink
        account {
          name
          email
          mobile
          profile
          logo {
            url
          }
          isVerified
        }
      }
      membershipInfo {
        showMembers
      }
      id
      type
      amount
      status
      coverImageId
      createdAt
      description
      expiredAt
      link
      name
      redirectUrl
      target
      terms
      updatedAt
      userId
      membershipTier {
        id
        name
        description
        name
        status
        limit
        membershipCustomer {
          id
          status
        }
      }
      coverImage {
        url
        id
      }
    }
  }
`;

export const GET_MEMBERSHIP_TO_UNSUBSCRIBE = gql`
  query getMembershipCustomerById($id: ID!) {
    getMembershipCustomerById(id: $id) {
      id
      status
      monthlyPaymentPeriod
      membershipTier {
        name
        description
        membershipTierPeriod {
          amount
          monthPeriod
        }
      }
      customer {
        name
        email
        mobile
        user {
          account {
            name
            logo {
              url
            }
          }
        }
      }
    }
  }
`;

export const UNSUBSCRIBE_FROM_MEMBERSHIP = gql`
  mutation unsubscribeFromMembership($id: ID!) {
    unsubscribeFromMembership(id: $id) {
      status
    }
  }
`;

export const GET_MEMBERSHIP_CUSTOMER_TOTAL = gql`
  query getTotalMembershipCustomer($membershipId: ID, $membershipTierId: ID) {
    getTotalMembershipCustomer(membershipId: $membershipId, membershipTierId: $membershipTierId) {
      total
    }
  }
`;

export const GQL_GET_MEMBERSHIP_LIST_CUSTOMER_PORTAL = gql`
  query getMembershipListCustomerPortal(
    $page: Int
    $pageSize: Int
    $id: ID
    $membershipName: String
  ) {
    getMembershipListCustomerPortal(
      page: $page
      pageSize: $pageSize
      id: $id
      membershipName: $membershipName
    ) {
      items {
        id
        customerId
        nextPayment
        nextPaymentEmailSent
        status
        membershipTierId
        paymentLinkTransactionId
        memberId
        createdAt
        subscriptionId
        xenditRecurringPaymentId
        createdAt
        monthlyPaymentPeriod
        isInTrial
        isAlreadyUsedTrial
        subscription {
          name
          description
          amount
          billingCycle
          billingCycleCustomPeriod
          billingCycleCustomPeriodTime
        }
        membershipTier {
          name
          description
          membershipTierPeriod {
            amount
            monthPeriod
            status
          }
          membershipTierProducts {
            id
            status
          }
        }
        paymentLink {
          id
          isDownloadable
          redirectUrl
          membershipInfo {
            type
          }
          courseSections {
            id
            name
            membershipTierContents {
              id
              membershipTierId
              status
            }
          }
        }
        customer {
          email
          name
          userId
          user {
            paymeLink
            account {
              name
            }
          }
        }
      }
      nextPage
    }
  }
`;

export const CHECK_MEMBERSHIP_PAYMENT = gql`
  mutation checkMembershipPayment($memberId: String!, $userId: ID!) {
    checkMembershipPayment(memberId: $memberId, userId: $userId) {
      id
      createdAt
      status
      memberId
      errorMessage
      totalPaymentOnThisMembershipTier
      thisMonthPaymentStatus
      nextPayment
      totalPaymentOnThisMembershipTier
      firstPaymentDateAfterTrialDate
      firstPaymentDateAfterTrialStatus
      monthlyPaymentPeriod
      isInTrial
      paymentLink {
        name
      }
      customer {
        name
      }
      membershipTier {
        name
        finishMembershipAt
      }
      membershipCustomer {
        id
        memberId
        membershipTierId
        membershipTier {
          name
        }
        paymentLink {
          name
        }
      }
    }
  }
`;

export const PAY_MEMBERSHIP_INVOICE = gql`
  mutation payMembershipBill($memberId: String!, $thisPayment: Boolean, $nextPayment: Boolean) {
    payMembershipBill(memberId: $memberId, thisPayment: $thisPayment, nextPayment: $nextPayment) {
      id
      link
    }
  }
`;

export const GET_MEMBERSHIP_CUSTOMER_PERIOD = gql`
  query getMembershipCustomerById($id: ID!) {
    getMembershipCustomerById(id: $id) {
      id
      membershipTierId
      monthlyPaymentPeriod
    }
  }
`;

export const GET_COURSE_SECTION_MEMBERSHIP = gql`
  query getCourseSectionByCustomerTier($membershipTierId: ID, $paymentLinkTransactionId: ID) {
    getCourseSectionByCustomerTier(
      membershipTierId: $membershipTierId
      paymentLinkTransactionId: $paymentLinkTransactionId
    ) {
      membershipTierId
      memberId
      membershipTierName
      isMembershipActive
      isContentAvailable
      membershipTierProducts {
        id
        paymentLinkId
        membershipTierId
        status
        position
        paymentLink {
          id
          coverImage {
            url
          }
          id
          name
          type
          multipleImage {
            url
          }
        }
      }
      courseSections {
        coverImageId
        isBlurCoverImage
        isPublicPreview
        description
        id
        name
        position
        link
        status
        userId
        courseModules {
          id
          name
          type
          position
          link
          description
          isPreview
          isContentNotProtected
          isVisible
          coachingUrl
          embedUrl
          quiz
          startAt
          endAt
          status
          content {
            file {
              id
              filename
              size
              mimetype
              createdAt
              bunnyVideoId
            }
            id
            createdAt
          }
        }
      }
      errorMessage
      paymentLink {
        user {
          paymeLink
          bunnyLibraryId
          account {
            name
            email
            profile
            logo {
              url
            }
          }
        }
        createdAt
        description
        link
        name
        id
        updatedAt
        coverImage {
          url
        }
      }
    }
  }
`;

export const GET_PRODUCTS_BY_CUSTOMER_TIER = gql`
  query getProductsByCustomerTier($id: ID, $membershipTierId: ID, $paymentLinkTransactionId: ID) {
    getProductsByCustomerTier(
      id: $id
      membershipTierId: $membershipTierId
      paymentLinkTransactionId: $paymentLinkTransactionId
    ) {
      id
      status
      createdAt
      updatedAt
      description
      name
      status
      redirectUrl
      isDownloadable
      writtenBy
      transcript
      readBy
      type
      coverImage {
        fileType
        url
      }
      multipleImage {
        url
      }
      content {
        id
        key
        updatedAt
        value
        createdAt
        file {
          filename
          createdAt
          id
          mimetype
          updatedAt
          url
        }
      }
    }
  }
`;
