import { gql } from '@apollo/client';

export const GQL_GET_AFFILIATE_PERFORMANCE = gql`
  query getAffiliatePerformancePage(
    $page: Int
    $pageSize: Int
    $search: BalanceHistorySearchInput!
    $paymentLinkId: ID
    $link: String
  ) {
    getAffiliatePerformancePage(
      page: $page
      pageSize: $pageSize
      search: $search
      paymentLinkId: $paymentLinkId
      link: $link
    ) {
      items {
        affiliateId
        merchantId
        paymentLinkId
        userId
        user {
          referralCode
          account {
            logo {
              url
            }
            name
            email
          }
        }
        totalSales
        totalTransaction
      }
      nextPage
      total
    }
  }
`;
