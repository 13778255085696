import { gql } from '@apollo/client';

export const GQL_GET_SUBMISSIONS_CUSTOMER = gql`
  query getSubmissionsCustomer($paymentLinkId: ID!, $assignmentId: ID) {
    getSubmissionsCustomer(paymentLinkId: $paymentLinkId, assignmentId: $assignmentId) {
      createdAt
      feedback
      grade
      id
      solution
      title
      status
      assignment {
        endAt
        id
        isMandatory
        name
        startAt
        status
        task
      }
    }
  }
`;
export const GQL_GET_SUBMISSIONS_QUIZ = gql`
  query getSubmissionsCustomer(
    $paymentLinkId: ID!
    $assignmentId: ID
    $paymentLinkTransactionId: ID
    $courseModuleId: ID
  ) {
    getSubmissionsCustomer(
      paymentLinkId: $paymentLinkId
      assignmentId: $assignmentId
      paymentLinkTransactionId: $paymentLinkTransactionId
      courseModuleId: $courseModuleId
    ) {
      createdAt
      updatedAt
      feedback
      grade
      id
      solution
      status
    }
  }
`;

export const GQL_INSERT_SUBMISSION_STUDENT = gql`
  mutation insertSubmissionStudent($input: SubmissionInput!) {
    insertSubmissionStudent(input: $input) {
      id
    }
  }
`;

export const GQL_EDIT_SUBMISSION_STUDENT = gql`
  mutation editSubmission($input: SubmissionInput) {
    editSubmission(input: $input) {
      assignmentId
      createdAt
      customerId
      feedback
      grade
      paymentLinkId
      solution
      status
      title
      updatedAt
      userId
    }
  }
`;

export const GQL_INSERT_SUBMISSION_QUIZ = gql`
  mutation insertSubmissionQuiz($input: SubmissionQuizInput!) {
    insertSubmissionQuiz(input: $input) {
      id
      updatedAt
    }
  }
`;

export const GQL_SUBMIT_SUBMISSION_QUIZ = gql`
  mutation submitSubmissionQuiz($input: SubmissionQuizInput!) {
    submitSubmissionQuiz(input: $input) {
      id
    }
  }
`;
