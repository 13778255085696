import { gql } from '@apollo/client';

export const PUBLIC_INSERT_CUSTOME_FIELD = gql`
  mutation insertDataToCustomeField(
    $input: CustomeFieldInput!
    $email: String!
    $mobile: String!
    $name: String!
  ) {
    insertDataToCustomeField(input: $input, email: $email, mobile: $mobile, name: $name) {
      id
    }
  }
`;
