import { gql } from '@apollo/client';

export const GQL_INSERT_REVIEW = gql`
  mutation insertReviewByCustomer($input: ReviewInput!) {
    insertReviewByCustomer(input: $input) {
      id
      createdAt
      customerId
      message
      rating
      paymentLinkId
      updatedAt
      userId
    }
  }
`;

export const GQL_EDIT_REVIEW = gql`
  mutation editReviewByCustomer($input: ReviewInput!) {
    editReviewByCustomer(input: $input) {
      id
      createdAt
      customerId
      message
      rating
      paymentLinkId
      updatedAt
      userId
    }
  }
`;

export const GQL_GET_REVIEW_PAGE_PUBLIC = gql`
  query getReviewPageByPaymentLinkId(
    $page: Int!
    $pageSize: Int!
    $paymeLink: String!
    $paymentLinkId: ID!
  ) {
    getReviewPageByPaymentLinkId(
      page: $page
      pageSize: $pageSize
      paymeLink: $paymeLink
      paymentLinkId: $paymentLinkId
    ) {
      items {
        createdAt
        id
        message
        rating
        updatedAt
        customer {
          name
        }
      }
      total
    }
  }
`;

export const GQL_GET_REVIEW_STATISTICS = gql`
  query getReviewStatisticsByPaymentLinkId($paymeLink: String!, $paymentLinkId: ID!) {
    getReviewStatisticsByPaymentLinkId(paymeLink: $paymeLink, paymentLinkId: $paymentLinkId) {
      total
      average
      stats {
        rating
        percentage
      }
    }
  }
`;

export const GQL_GET_TESTIMONY = gql`
  query getTestimonyLandingPromotion($landingPromotionId: ID!) {
    getTestimonyLandingPromotion(landingPromotionId: $landingPromotionId) {
      id
      position
      review {
        rating
        message
        customer {
          name
        }
      }
    }
  }
`;

export const GET_CUSTOMER_REVIEW = gql`
  query getCustomerReview($paymentLinkId: ID!) {
    getCustomerReview(paymentLinkId: $paymentLinkId) {
      id
      message
      rating
    }
  }
`;
