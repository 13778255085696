import { gql } from '@apollo/client';

export const GQL_GET_WRITING_DETAIL_LIST_ACCESS = gql`
  query getWritingContentListAccessCustomer(
    $paymentLinkId: ID!
  ) {
    getWritingContentListAccessCustomer(
      paymentLinkId: $paymentLinkId
    ) {
      id
      name
      description
      link
      writingType
      coverImage {
        url
        width
        height
      }
      metadata {
        author
        language
        genre {
          name
        }
      }
      writingChapters {
        id
        releasedAt
        status
        title
      }
      user {
        account {
          name
        }
      }
    }
  }
`;

export const GQL_GET_WRITING_DETAIL_ACCESS = gql`
  query getWritingContentDetailAccessCustomer(
    $paymentLinkId: ID!, $writingChapterId: ID
  ) {
    getWritingContentDetailAccessCustomer(
      paymentLinkId: $paymentLinkId, writingChapterId: $writingChapterId
    ) {
      id
      content
      errorMessage
      writingChapter {
        id
        title
        position
        coverImage {
          url
          width
          height
        }
      }
      nextPageChapter {
        id
        title
      }
      prevPageChapter {
        id
        title
      }
      paymentLink {
        id
        name
        link
        writingType
        coverImage {
          url
          width
          height
        }
        writingChapters {
          id
          title
          status
          releasedAt
        }
        user {
          account {
            name
          }
        }
      }
    }
  }
`;

export const GQL_GET_WRITING_FREE_CONTENT = gql`
  query getFreeContentWriting(
    $paymentLinkId: ID!, $writingChapterId: ID, $writingType: WritingType!
  ) {
    getFreeContentWriting(
      paymentLinkId: $paymentLinkId,
      writingChapterId: $writingChapterId,
      writingType: $writingType
    ) {
      id
      content
      errorMessage
      paymentLink {
        name
        coverImage {
          url
          width
          height
        }
      }
      writingChapter {
        id
        title
        coverImage {
          url
          width
          height
        }
      }
    }
  }
`;
