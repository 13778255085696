import { gql } from '@apollo/client';

export const GQL_GET_PHYSICAL_PRODUCTS_BY_USERNAME = gql`
  query getPhysicalProductsByUsername(
    $username: String!
    $page: Int
    $pageSize: Int
    $link: String
  ) {
    getPhysicalProductsByUsername(
      username: $username
      page: $page
      pageSize: $pageSize
      link: $link
    ) {
      items {
        user {
          account {
            name
            email
            profile
            logo {
              url
            }
            isVerified
          }
        }
        id
        link
        name
        category
        qty
        amount
        status
        description
        multipleImage {
          id
          url
          createdAt
          updatedAt
        }
        order {
          discountAmount
          sku
          variant
          vendor
          wholesaleAmount
        }
      }
      total
    }
  }
`;
