import { gql } from '@apollo/client';

export const GQL_PROXY_EVENT_HISTORY_PUBLIC = gql`
  query getEventHistoryPublic(
    $page: Int
    $pageSize: Int
    $search: EventHistorySearchInput
    $ticketId: ID!
  ) {
    getEventHistoryPublic(page: $page, pageSize: $pageSize, search: $search, ticketId: $ticketId) {
      total
      page
      offset
      totalPage
      prevPage
      nextPage
    }
  }
`;

export const GET_RECORDED_EVENT = gql`
  query getRecordedEvent($productId: ID!) {
    getRecordedEvent(productId: $productId) {
      id
      type
      name
      embedUrl
      description
      isContentNotProtected
      paymentLink {
        coverImage {
          url
        }
      }
    }
  }
`;
