import { gql } from "@apollo/client";

export const GQL_GET_FUNDRAISINGS_BY_USERNAME = gql`
  query getFundraisingsByUsername(
    $username: String!
    $page: Int
    $pageSize: Int
    $id: ID
    $link: String
    $status: String
    $category: String
    $sortField: PaymentLinkSortField
    $sortDirection: SortDirection
    $search: PaymentLinkSearchInput
    $searchAny: PaymentLinkSearchInput
  ) {
    getFundraisingsByUsername(
      username: $username
      page: $page
      pageSize: $pageSize
      id: $id
      link: $link
      status: $status
      category: $category
      sortField: $sortField
      sortDirection: $sortDirection
      search: $search
      searchAny: $searchAny
    ) {
      items {
        user {
          paymeLink
          account {
            name
            email
            profile
            address
            mobile
            logo {
              url
              width
              height
            }
            isVerified
          }
        }
        id
        amount
        status
        category
        coverImageId
        createdAt
        description
        startAt
        expiredAt
        link
        type
        name
        reason
        receiver
        redirectUrl
        target
        updatedAt
        usage
        userId
        fundraisingSettings
        fundraisingUsagePlan
        subType
        ackeeAnalytics {
          analyticDomainId
          analyticEventId
          eventType
        }
        coverImage {
          url
          width
          height
          id
        }
        multipleImage {
          url
          width
          height
          updatedAt
        }
        checkoutSettings {
          buttonName
        }
        conversionSettings {
          trackingId
          type
          conversionSettingEvents {
            name
            page
          }
        }
      }
      total
    }
  }
`;

export const GQL_FUNDRAISING_HISTORY = gql`
  query getFundraisingHistoryProxy($paymentLinkId: ID) {
    getFundraisingHistoryProxy(paymentLinkId: $paymentLinkId) {
      total
    }
  }
`;

export const GQL_GET_TOTAL_FUNDRAISING_BY_PAYMENT_LINK_ID = gql`
  query getTotalFundraisingsByPaymentLinkID($id: ID, $link: String) {
    getTotalFundraisingsByPaymentLinkID(id: $id, link: $link) {
      totalFundraising
      target
    }
  }
`;

export const GQL_GET_COMMENT_FUNDRAISING_BY_PAYMENT_LINK_ID = gql`
  query getCommentsFundraisingByPaymentLinkID(
    $id: ID
    $link: String
    $page: Int
    $pageSize: Int
  ) {
    getCommentsFundraisingByPaymentLinkID(
      id: $id
      link: $link
      page: $page
      pageSize: $pageSize
    ) {
      items {
        id
        message
        amount
        updatedAt
        customer {
          name
        }
      }
      total
    }
  }
`;
