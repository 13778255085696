import { gql } from '@apollo/client';

export const GQL_CREATE_SUBSCRIPTION_CUSTOMER = gql`
  mutation createSubscriptionCustomer($input: CreateSubscriptionCustomerInput!) {
    createSubscriptionCustomer(input: $input) {
      invoiceUrl
      initPayment
      paymentAtStart
      id
      errorMessage
    }
  }
`;

export const GQL_UNSUBSCRIBE = gql`
  mutation unsubscribe($id: ID!) {
    unsubscribe(id: $id) {
      success
      errorMessage
    }
  }
`;

export const GQL_GET_SUBSCRIPTION_BY_LINK = gql`
  query getSubscriptionByLinkPublic($link: String!) {
    getSubscriptionByLinkPublic(link: $link) {
      id
      amount
      billingCycle
      billingCycleAt
      billingCycleCustomPeriod
      billingCycleCustomPeriodTime
      createdAt
      initPayment
      initPaymentAmount
      limit
      link
      name
      description
      paymentAtStart
      redirectUrl
      updatedAt
      userId
      status
      form {
        fieldObject
      }
      user {
        kyc {
          status
        }
        account {
          name
          logo {
            url
          }
        }
      }
    }
  }
`;

export const GQL_GET_SUBSCRIPTION_CUSTOMER_PUBLIC = gql`
  query getSubscriptionCustomerPublic($id: ID!, $isRecentTransaction: Boolean) {
    getSubscriptionCustomerPublic(id: $id, isRecentTransaction: $isRecentTransaction) {
      id
      customerId
      nextPayment
      subscriptionId
      xenditRecurringPaymentRsp
      currentXenditInvoiceId
      status
      customer {
        name
        email
        mobile
      }
      subscription {
        name
        amount
        amount
        billingCycle
        billingCycleAt
        billingCycleCustomPeriod
        billingCycleCustomPeriodTime
        initPayment
        initPaymentAmount
        description
        paymentAtStart
        redirectUrl
        link
        user {
          account {
            name
            logo {
              url
            }
            xenditUserId
          }
          payChannelConfig {
            config
          }
        }
      }
    }
  }
`;
