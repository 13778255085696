import { gql } from '@apollo/client';

export const GQL_CREATE_ACCESS_CUSTOMER_PORTAL = gql`
  mutation createAccessCustomerPortal($email: String, $link: String) {
    createAccessCustomerPortal(email: $email, link: $link) {
      status
      message
      user
    }
  }
`;

export const GQL_GET_ALL_INVOICE_CUSTOMER_PORTAL = gql`
  query getAllInvoiceCustomerPortal(
    $page: Int
    $pageSize: Int
    $id: ID
    $invoiceName: String
    $isGift: Boolean
    $startAt: String
    $endAt: String
  ) {
    getAllInvoiceCustomerPortal(
      page: $page
      pageSize: $pageSize
      id: $id
      invoiceName: $invoiceName
      isGift: $isGift
      startAt: $startAt
      endAt: $endAt
    ) {
      items {
        id
        invoiceLink
        invoice {
          id
          amount
          status
          description
          paidAt
          payerEmail
          paymentChannel
        }
        customer {
          name
          email
        }
        paymentLink {
          id
          name
          type
          redirectUrl
          notes
        }
        subscription {
          name
        }
        user {
          id
          account {
            name
          }
        }
      }
      nextPage
    }
  }
`;

export const GQL_GET_SUBSCRIPTION_LIST_CUSTOMER_PORTAL = gql`
  query getSubscriptionListCustomerPortal($page: Int, $pageSize: Int, $id: ID, $subsName: String) {
    getSubscriptionListCustomerPortal(
      page: $page
      pageSize: $pageSize
      id: $id
      subsName: $subsName
    ) {
      items {
        id
        customerId
        nextPayment
        nextPaymentEmailSent
        status
        subscriptionId
        xenditRecurringPaymentId
        createdAt
        subscription {
          name
          description
          amount
          billingCycle
          billingCycleCustomPeriod
          billingCycleCustomPeriodTime
        }
        customer {
          email
          name
          userId
          user {
            account {
              name
            }
          }
        }
      }
      total
      page
      offset
      totalPage
      prevPage
      nextPage
    }
  }
`;

export const GQL_GET_INVOICE_BY_SUBSCRIPTION_ID_CUSTOMER_PORTAL = gql`
  query getInvoiceSubscriptionCustomerPortal(
    $page: Int
    $pageSize: Int
    $subscriptionCustomerId: ID
  ) {
    getInvoiceSubscriptionCustomerPortal(
      page: $page
      pageSize: $pageSize
      subscriptionCustomerId: $subscriptionCustomerId
    ) {
      items {
        id
        invoice {
          status
          amount
          paidAt
        }
      }
      total
    }
  }
`;

export const GQL_GET_INVOICE_BY_MEMBERSHIP_ID_CUSTOMER_PORTAL = gql`
  query getInvoiceMembershipCustomerPortal(
    $membershipCustomerId: ID
    $page: Int
    $pageSize: Int
    $sortDirection: SortDirection
    $sortField: BalanceHistorySortField
  ) {
    getInvoiceMembershipCustomerPortal(
      membershipCustomerId: $membershipCustomerId
      page: $page
      pageSize: $pageSize
      sortDirection: $sortDirection
      sortField: $sortField
    ) {
      items {
        id
        status
        credit
      }
      total
    }
  }
`;

export const GQL_GET_CUSTOMER_PORTAL = gql`
  query getCustomerPortal {
    getCustomerPortal {
      id
      name
      email
      user {
        account {
          name
          logo {
            url
          }
        }
      }
    }
  }
`;

export const GQL_GET_PAYMENT_LINK_PAGE_CUSTOMER_PORTAL = gql`
  query getPaymentLinkPageCustomerPortal(
    $page: Int
    $pageSize: Int
    $type: PaymentLinkType!
    $id: ID
    $paymentLinkTransactionId: ID
  ) {
    getPaymentLinkPageCustomerPortal(
      page: $page
      pageSize: $pageSize
      type: $type
      id: $id
      paymentLinkTransactionId: $paymentLinkTransactionId
    ) {
      items {
        id
        amount
        status
        createdAt
        description
        link
        name
        isDownloadable
        redirectUrl
        updatedAt
        eventStartAt
        eventEndAt
        writtenBy
        transcript
        readBy
        coverImage {
          url
        }
        content {
          file {
            filename
            url
            size
            mimetype
            createdAt
          }
        }
        certificate {
          layout
          name
          templateImage {
            url
            title
          }
        }
        user {
          account {
            name
          }
        }
      }
      page
      total
      customer {
        id
        email
        name
        mobile
        userId
        user {
          id
          name
          account {
            name
          }
        }
      }
    }
  }
`;

export const GQL_GET_TRANSACTION_HISTORY_CUSTOMER_PAGE = gql`
  query getTransactionHistoryCustomerPage($page: Int, $pageSize: Int, $type: BalanceHistoryType!) {
    getTransactionHistoryCustomerPage(page: $page, pageSize: $pageSize, type: $type) {
      items {
        id
        credit
        paymentMethod
        trackOrderId
        orderStatus
        xenditTransactionId
        certificateHistory {
          id
          serialNumber
          code
        }
        paymentLinkTransactionId
        paymentLinkTransaction {
          amount
          orderVariant
        }
        orderDetail {
          awbNumber
        }
        xenditTransaction {
          paymentLinkTransaction {
            amount
            orderVariant
          }
        }
        subscription {
          name
          description
          link
        }
        customer {
          name
        }
        paymentLink {
          id
          name
          link
          description
          createdAt
          eventUrl
          eventStartAt
          eventEndAt
          certificate {
            layout
            name
            templateImage {
              url
              title
            }
          }
          user {
            id
            account {
              name
            }
          }
        }
      }
      total
      page
    }
  }
`;

export const GQL_GET_TRANSACTION_HISTORY_CUSTOMER = gql`
  query getTransactionHistoryCustomer($id: ID!) {
    getTransactionHistoryCustomer(id: $id) {
      id
      credit
      createdAt
      paymentMethod
      trackOrderId
      orderStatus
      balanceHistoryType
      certificateHistory {
        id
        serialNumber
        code
      }
      orderDetail {
        awbNumber
      }
      xenditTransaction {
        id
        paymentLinkTransaction {
          id
          orderVariant
          status
          couponUsage {
            coupons {
              code
              discount {
                value
                discountType
              }
            }
          }
        }
      }
      paymentLinkTransaction {
        id
        orderVariant
        status
        ticketUrl
        createdAt
        amount
        couponUsage {
          coupons {
            code
            discount {
              value
              discountType
            }
          }
        }
      }
      customer {
        id
        name
        email
        mobile
        customerAddress {
          id
          district
          subdistrict
          area
        }
      }
      subscription {
        name
        description
        link
      }
      paymentLink {
        id
        name
        link
        description
        amount
        createdAt
        eventUrl
        eventStartAt
        eventEndAt
        notes
        terms
        type
        isDownloadable
        redirectUrl
        order {
          variant
          sku
        }
        multipleImage {
          url
        }
        certificate {
          layout
          name
          startAt
          templateImage {
            url
            title
          }
        }
        user {
          id
          account {
            name
          }
        }
      }
      user {
        account {
          name
        }
        paymeLink
      }
    }
  }
`;

export const GQL_GET_COURSE_CUSTOMER_PORTAL = gql`
  query getCourseCustomerById($id: ID, $membershipTierId: ID, $paymentLinkTransactionId: ID) {
    getCourseCustomerById(
      id: $id
      membershipTierId: $membershipTierId
      paymentLinkTransactionId: $paymentLinkTransactionId
    ) {
      user {
        bunnyLibraryId
        account {
          name
          email
          profile
          logo {
            url
          }
        }
      }
      certificate {
        layout
        name
        startAt
        isViewAllModulesRequired
        templateImage {
          url
          title
        }
      }
      certificateHistory {
        id
        serialNumber
        code
        paymentLinkTransactionId
      }
      courseSections {
        description
        id
        name
        position
        link
        courseModules {
          id
          name
          type
          timerInMinutes
          position
          link
          description
          isPreview
          isContentNotProtected
          isVisible
          isRetryDisabled
          coachingUrl
          embedUrl
          quiz
          startAt
          endAt
          status
          width
          height
          content {
            files {
              id
              filename
              size
              mimetype
              createdAt
            }
            file {
              id
              filename
              size
              mimetype
              createdAt
              bunnyVideoId
            }
            id
            createdAt
          }
        }
      }
      amount
      createdAt
      category
      qty
      description
      expiredAt
      limit
      link
      name
      id
      status
      updatedAt
      isViewAllModulesInOrderRequired
      gradeThreshold
      paymentLinkTransactionId
      isAllModulesViewed
      courseModulesNotes
      coverImage {
        url
      }
      customer {
        name
        id
      }
      reviews {
        id
        rating
        message
      }
    }
  }
`;

export const GQL_GET_ALL_PRODUCTS = gql`
  query getAllCustomerProducts(
    $page: Int
    $pageSize: Int
    $id: ID
    $productType: String!
    $searchAny: String
  ) {
    getAllCustomerProducts(
      page: $page
      pageSize: $pageSize
      id: $id
      productType: $productType
      searchAny: $searchAny
    ) {
      items {
        id
        paymentMethod
        xenditTransactionId
        balanceHistoryType
        certificateHistory {
          id
          serialNumber
          code
        }
        customer {
          id
          name
        }
        paymentLinkTransaction {
          id
          createdAt
          isAllModulesViewed
        }
        paymentLink {
          id
          name
          createdAt
          eventUrl
          eventStartAt
          eventEndAt
          type
          isDownloadable
          redirectUrl
          gradeThreshold
          coverImage {
            url
          }
          multipleImage {
            url
          }
          certificate {
            layout
            name
            startAt
            isViewAllModulesRequired
            templateImage {
              url
              title
            }
          }
          content {
            file {
              url
            }
          }
          user {
            account {
              name
            }
          }
        }
      }
      nextPage
    }
  }
`;

export const GQL_GET_COHORT_CUSTOMER_PORTAL = gql`
  query getCohortCustomerById($id: ID!) {
    getCohortCustomerById(id: $id) {
      user {
        paymeLink
        bunnyLibraryId
        account {
          name
          email
          profile
          logo {
            url
          }
        }
      }
      cohortSessions {
        description
        eventEndAt
        eventStartAt
        eventUrl
        id
        name
        position
        speaker
        speakerProfile
        createdAt
        isOnlineEvent
        mapLocation
      }
      courseSections {
        description
        id
        name
        position
        link
        courseModules {
          id
          name
          type
          position
          link
          description
          coachingUrl
          embedUrl
          isContentNotProtected
          quiz
          startAt
          endAt
          status
          width
          height
          content {
            files {
              id
              filename
              size
              mimetype
              createdAt
            }
            file {
              id
              filename
              size
              mimetype
              createdAt
              bunnyVideoId
            }
            id
            createdAt
          }
        }
      }
      assignments {
        task
        status
        startAt
        name
        isMandatory
        id
        endAt
      }
      reviews {
        id
        rating
        message
      }
      amount
      createdAt
      category
      qty
      description
      expiredAt
      limit
      link
      name
      id
      status
      updatedAt
      coverImage {
        url
      }
      certificate {
        layout
        name
        startAt
        templateImage {
          url
          title
        }
      }
      certificateHistory {
        id
        serialNumber
        code
      }
      customer {
        name
      }
      paymentLinkTransaction {
        createdAt
      }
    }
  }
`;
