import { gql } from '@apollo/client';

export const GET_MEMBERSHIP_TIER_PERIOD = gql`
  query getMembershipTierAmount($membershipTierId: ID!, $monthPeriod: Int!) {
    getMembershipTierAmount(membershipTierId: $membershipTierId, monthPeriod: $monthPeriod) {
      amount
    }
  }
`;

export const GQL_GET_INVOICE_BY_ID = gql`
  query getInvoiceById($xenditTransactionId: ID!) {
    getInvoiceById(xenditTransactionId: $xenditTransactionId) {
      total
      page
      offset
      totalPage
      prevPage
      nextPage
      items {
        accountPlanType
        subscriptionCustomer {
          id
          createdAt
          customer {
            name
            email
            mobile
            user {
              account {
                name
                email
                mobile
                address
                logo {
                  url
                }
              }
            }
          }
          subscription {
            name
            amount
          }
        }
        balanceHistory {
          paymentMethod
          balanceHistoryType
          debit
        }
        paymentLinkTransaction {
          id
          isAdminFeeBorneByCustomer
          isChannelFeeBorneByCustomer
          membershipTierId
          membershipMonthlyPeriod
          createdAt
          amount
          orderVariant
          userId
          couponUsage {
            discount {
              value
              discountType
            }
          }
          customer {
            name
            email
            mobile
            user {
              account {
                name
                email
                mobile
                address
                logo {
                  url
                }
              }
            }
          }
          paymentLink {
            name
            expiredAt
            amount
            items
            type
            tax
            notes
            description
            variant
            order {
              sku
              variant
            }
            event {
              ticketEvent {
                id
                name
                amount
                quantity
              }
            }
          }
          items {
            orderVariant
            amount
            id
            paymentLinkId
            paymentLink {
              items
              tax
              redirectUrl
              order {
                sku
                variant
              }
              event {
                ticketEvent {
                  id
                  name
                  quantity
                  amount
                }
              }
              id
              amount
              createdAt
              customerId
              description
              expiredAt
              limit
              link
              status
              name
              redirectUrl
              subscriptionId
              type
              updatedAt
              userId
              eventStartAt
              eventUrl
              notes
              variant
            }
          }
        }
        invoiceLink
        invoice {
          status
          payerEmail
          amount
          description
          paidAt
        }
      }
    }
  }
`;

export const GET_INVOICE_BY_PAYMENT_TRANS_ID = gql`
  query getInvoice($paymentTransId: ID!) {
    getInvoice(paymentTransId: $paymentTransId) {
      id
      amount
      customer {
        email
        name
        mobile
        user {
          account {
            email
            name
            mobile
            address
            logo {
              url
            }
          }
        }
      }
      paymentLink {
        amount
        items
        tax
        expiredAt
        notes
        description
        status
        type
        installmentId
      }
    }
  }
`;
