import { gql } from '@apollo/client';

export const GQL_GET_LOCATION_BY_KEYWORD = gql`
  query getLocationByKeywordProxy($keyword: String) {
    getLocationByKeywordProxy(keyword: $keyword) {
      area_id
      city
      province_name
      subdistrict_name
      district_name
      postcode
      province_name
      area_name
    }
  }
`;

export const GQL_GET_LOCATION_BY_KECAMATAN = gql`
  query getDistictKiriminAjabyName($keyword: String) {
    getDistictKiriminAjabyName(keyword: $keyword) {
      area_id
      city
      province_name
      subdistrict_name
      district_name
      postcode
      province_name
      area_name
    }
  }
`;
